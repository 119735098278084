import React from "react";

export default ({ color }) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.96259 7.81867C5.11788 8.34321 6.47965 7.8319 7.0042 6.67661C7.52874 5.52132 7.01743 4.15955 5.86214 3.635C4.70685 3.11046 3.34508 3.62178 2.82054 4.77706C2.29599 5.93235 2.80731 7.29412 3.96259 7.81867Z" fill="#333333" />
    <path d="M6.01566 7.61653C6.96089 8.04571 8.07507 7.62736 8.50424 6.68212C8.93342 5.73689 8.51507 4.62271 7.56983 4.19354C6.6246 3.76436 5.51042 4.18271 5.08125 5.12795C4.65207 6.07318 5.07042 7.18736 6.01566 7.61653Z" fill="#73A77B" />
    <path d="M6.58687 42.1003C8.04632 41.2245 8.51946 39.3315 7.64366 37.872C6.76786 36.4126 4.87477 35.9394 3.41532 36.8152C1.95587 37.691 1.48273 39.5841 2.35853 41.0436C3.23433 42.503 5.12742 42.9761 6.58687 42.1003Z" fill="#333333" />
    <path d="M7.92813 39.6796C9.12222 38.9631 9.50934 37.4142 8.79278 36.2201C8.07621 35.026 6.52732 34.6389 5.33322 35.3554C4.13913 36.072 3.75201 37.6209 4.46858 38.815C5.18514 40.0091 6.73403 40.3962 7.92813 39.6796Z" fill="#FFB000" />
    <path d="M18.7916 23.8327C18.7916 25.0977 17.765 26.1243 16.5 26.1243C15.235 26.1243 14.2083 25.0977 14.2083 23.8327C14.2083 22.5677 15.235 21.541 16.5 21.541C17.765 21.541 18.7916 22.5677 18.7916 23.8327ZM27.5 21.541C26.235 21.541 25.2083 22.5677 25.2083 23.8327C25.2083 25.0977 26.235 26.1243 27.5 26.1243C28.765 26.1243 29.7916 25.0977 29.7916 23.8327C29.7916 22.5677 28.765 21.541 27.5 21.541ZM40.3333 21.9993C40.3333 32.1193 32.12 40.3327 22 40.3327C11.88 40.3327 3.66663 32.1193 3.66663 21.9993C3.66663 11.8793 11.88 3.66602 22 3.66602C32.12 3.66602 40.3333 11.8793 40.3333 21.9993ZM36.6666 21.9993C36.6666 20.5693 36.4466 19.1943 36.0616 17.8927C34.7783 18.1677 33.4583 18.3327 32.0833 18.3327C26.345 18.3327 21.23 15.6927 17.8566 11.5677C15.9316 16.261 12.1 19.946 7.33329 21.7427C7.35163 21.816 7.33329 21.9077 7.33329 21.9993C7.33329 30.0843 13.915 36.666 22 36.666C30.085 36.666 36.6666 30.0843 36.6666 21.9993Z" fill="#333333" />
    <path d="M32.7413 26.7859L41.5773 28.4964L39.1065 41.2595L30.2706 39.549L32.7413 26.7859Z" fill="#FFD579" />
    <path d="M41.5496 25.5197L33.3697 23.9278C32.4697 23.7535 31.5909 24.3473 31.4167 25.2473L28.5658 39.9739C28.3916 40.8738 28.9854 41.7527 29.8853 41.9269L38.0668 43.5107C38.9668 43.685 39.8456 43.0912 40.0198 42.1912L42.8707 27.4646C43.0449 26.5647 42.4495 25.694 41.5496 25.5197ZM38.7003 40.2382L30.5189 38.6544L32.7362 27.2003L40.9176 28.7841L38.7003 40.2382Z" fill="#333333" />
    <path d="M36.1418 32.1888C35.0542 32.1888 34.1726 33.0704 34.1726 34.1579C34.1726 35.2454 35.0542 36.1271 36.1418 36.1271C37.2293 36.1271 38.1109 35.2454 38.1109 34.1579C38.1109 33.0704 37.2293 32.1888 36.1418 32.1888Z" fill="#333333" />
    <path d="M34.6111 33.0741C33.7213 33.0741 33 33.7954 33 34.6852C33 35.575 33.7213 36.2963 34.6111 36.2963C35.5009 36.2963 36.2222 35.575 36.2222 34.6852C36.2222 33.7954 35.5009 33.0741 34.6111 33.0741Z" fill="#F76E9C" />
  </svg>
);
